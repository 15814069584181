// import article01 from './Assets/IPOMarket.pdf';
import cnbc from '../assets/cnbc-logo.jpg';
import Graphcore from '../assets/Graphcore-Logo.png'
import Influx from '../assets/InfluxDB-Logo.png'
import Orca from '../assets/Orca-Logo.png'
import NewsLink from '../components/NewsLink';

export default function Resources() {
    return (
        <>
        <div className='tabPadding'>
            <div className="head-text bluetext mb-4">Resources and News </div>
            <NewsLink 
                img={Orca}
                headline={' Orca Security Named to 2023 Forbes Cloud 100 '}
                link={'https://orca.security/resources/press-releases/orca-security-2023-forbes-cloud-100/'} 
            />

            <NewsLink 
                img={cnbc}
                headline={' Signs are emerging that the IPO market can make a comeback soon '}
                link={'https://www.cnbc.com/2023/07/23/signs-are-emerging-that-the-ipo-market-can-make-a-comeback-soon.html'} 
            />

            <NewsLink 
                img={Influx}
                headline={' Time Series Data At Scale: Unlocking Real-Time Innovation '}
                link={'https://www.forbes.com/sites/forbestechcouncil/2023/07/05/time-series-data-at-scale-unlocking-real-time-innovation/?sh=f9ac6d61d8d7'} 
            />

            <NewsLink 
                img={Graphcore}
                headline={' GPUs aren’t always your best bet, Twitter ML tests suggest '}
                link={'https://www.theregister.com/2022/06/16/graphcore_nvidia_gpus/'} 
            />

            <NewsLink 
                img={Graphcore}
                headline={' The future of AI is a software story, says Graphcore’s CEO '}
                link={'https://www.zdnet.com/article/the-future-of-ai-is-a-software-story-says-graphcores-ceo/'} 
            />

            <NewsLink 
                img={Graphcore}
                headline={' Graphcore takes on Nvidia with UK AI datacentre offer '}
                link={'https://www.eenewseurope.com/en/graphcore-takes-on-nvidia-with-uk-ai-datacentre-offer/'} 
            />
        </div>

        </>
    )
}