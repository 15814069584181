import Modal from 'react-bootstrap/Modal';

export default function teamModal(props) {
    return (
      <Modal size='lg'
        {...props}
        style={{opacity:1}}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <p>
            {props.body}
          </p>
        </Modal.Body>
      </Modal>
    );
  }