import { HashLink as Link} from 'react-router-hash-link';
import { PopupButton } from 'react-calendly';
import Logo from '../assets/logo.svg'
import Hamburger from '../components/Hamburger';
import { useState,  useEffect } from 'react';


function Navbar() {

    const [hamburgerOpen, setHamburgerOpen] = useState(true)


    function getCurrentDimension(){
        return {
              width: window.innerWidth,
              height: window.innerHeight
        }
    }

    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    useEffect(() => {
        const updateDimension = () => {
          setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);
        
        return(() => {
            window.removeEventListener('resize', updateDimension);
        })
      }, [screenSize]);
    
    useEffect(() => {
        if (screenSize.width > 750) {
            setHamburgerOpen(true);
        }
        else {
            setHamburgerOpen(false);
        }
      }, [screenSize]);


    function toggleHamburger() {
        setHamburgerOpen(!hamburgerOpen);
    }

    function closeOnTabClick() {
        if (screenSize.width < 750) {
            setHamburgerOpen(false)
        }
    }

    return ( 
        <>
        {hamburgerOpen &&
            <div className="navbar navbar-expand-md d-flex sticky-top justify-content-between bg-white text-dark">
                <div className="col-lg-1"></div>
                <div className="col-1 ms-lg-5">
                    <a href='#home'><img src={Logo} className="logo mt-1" alt="logo"></img></a>
                    
                </div>
                <div className="col d-flex align-items-center justify-content-end">
                    <ul className="navbar-nav d-flex align-items-center justify-content-end">
                        <Link className='navbar-items' smooth to='#home' onClick={closeOnTabClick}><li >Home</li></Link>
                        <Link className='navbar-items' smooth to='#about' onClick={closeOnTabClick}><li >About Vidya</li></Link>
                        <Link className='navbar-items' smooth to='#areas' onClick={closeOnTabClick}><li >Focus Areas</li></Link>
                        <Link className='navbar-items' smooth to='#strategy' onClick={closeOnTabClick}><li >Strategy</li></Link>
                        <Link className='navbar-items' smooth to='#portfolio' onClick={closeOnTabClick}><li >Portfolio</li></Link>
                        <Link className='navbar-items' smooth to='#resources' onClick={closeOnTabClick}><li >Resources and News</li></Link>
                        <Link className='navbar-item-last' smooth to='#contact' onClick={closeOnTabClick}><li >Contact Us</li></Link>

                        <li >
                            <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet"></link>
                            <script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript" async></script>
                            
                            <PopupButton
                                url="https://calendly.com/ftconsultants/30min?hide_event_type_details=1&hide_gdpr_banner=1"
                                rootElement={document.getElementById("root")}
                                className='callButtonNav'
                                text="Book a Call"
                            />
                            
                        </li>
                    </ul>
                    <div className='hamburger' onClick={toggleHamburger}>
                        <Hamburger />
                    </div>
                </div>
                <div className="col-lg-1"></div>
            </div>
        
            
        }
        {!hamburgerOpen && 
            <div className='sticky-top bg-white d-flex justify-content-between '>
                <div className="col-1 ms-lg-5">
                    <a href='#home'><img src={Logo} className="logo mt-1" alt="logo"></img></a>    
                </div>
                <div className='hamburger pe-5' onClick={toggleHamburger}>
                    <Hamburger />
                </div>
            </div>
                
        }
    </>
        
        
    );
}

export default Navbar;