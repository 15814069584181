import './App.css';
import Navbar from './sections/Navbar'
import Home from './Home'

function App() {
  return (
  <>
    <head>
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/css/bootstrap.min.css" 
      integrity="sha384-9gVQ4dYFwwWSjIDZnLEWnxCjeSWFphJiwGPXr1jddIhOegiu1FwO5qRGvFXOdJZ4" 
      crossorigin="anonymous"></link>
    </head>
    <body>
      <Navbar />
        <body>
            <Home />
        </body>
    </body>

  </>
  );
}

export default App;
