export default function Hamburger() {
    return(
        <>
            <div className="hamburger">
                <div className="burger"></div>
                <div className="burger"></div>
                <div className="burger"></div>
            </div>
        </>
    )
}