import Head from './sections/Head'
import AboutVidya from './sections/AboutVidya'
import Team from './sections/Team'
import AreasOfInterest from './sections/Areas';
import Strategy from './sections/Strategy';
import HomeFooter from './sections/homeFooter';
import Resources from './sections/Resources';

function Home() {
    return (
        <>
            <div id='home'></div>
            <Head />
            <div id='about'><AboutVidya /></div>
            <Team />
            <div id='areas'><AreasOfInterest /></div>
            <div id='strategy'><Strategy /></div> 
            <div id='resources'><Resources /></div>          
            <div id='contact'><HomeFooter /></div>      
            
        </>
    )
}

export default Home;