import { PopupButton } from 'react-calendly';
import Image1 from '../assets/1.png';

function Head() {
    return (
        <>
            <div className="container-fluid row mt-5 p-lg-5 bg-light">

                <div className="col-lg-1"></div>
                <div className="col-lg pt-5">
                        <div className="head-text-top w-100"><span className="bluetext">Contributing to Humanity's Progress</span> Through Investment.</div>
                        <div className="body-text-top">Through or late-stage private investments, we support technical revolutionaries who
                        are working to find solutions for a brighter world. <br/>
                        Want to know how Vidya Advisory can help you navigate late-stage investments?
                        </div>
                        <PopupButton
                                    url="https://calendly.com/ftconsultants/30min?hide_event_type_details=1&hide_gdpr_banner=1"
                                    rootElement={document.getElementById("root")}
                                    className='callButton'
                                    text="Book a Discovery Call"
                        />
                    

                </div>

                <div className="col-lg text-center pt-5">

                        <img src={Image1} className="Image1" alt="" />
                </div>
                <div className="col-lg-1"></div>
            </div>
        </>
    );
}

export default Head;