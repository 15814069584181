import { PopupButton } from 'react-calendly';

function AboutVidya() {
    return (
        <>
            <div className="bluebg">
                <div className="row p-5">
                    <div className="col-lg-1"></div>
                    <div className="col-3 mt-5 pt-5">
                        <div className="head-text">About <span className="whitetext"> Vidya Advisory</span></div>
                    </div>
                    <div className="col-lg-7">
                        <div className="body-text">
                            <span className="whitetext">
                            We are a venture capital firm that advises and deploys investors' capital in privately held 
                            companies. These companies are disruptors, technologically superior, and working on revolutionizing 
                            the world - with solid products and services or game-changing technology that benefits humanity and 
                            works towards a sustainable future.
                            <br />
                            <br/>
                            We are not shy about taking risks with early stage companies. However, our focus is investing in the late stage, 
                            with proven products and services that have demonstrated commercial viability and are still growing. We look for 
                            companies that are addressing a large Total Accessible Market (TAM).
                            <br/>
                            <br/>
                            </span>
                        </div>
                        <PopupButton
                            url="https://calendly.com/ftconsultants/30min?hide_event_type_details=1&hide_gdpr_banner=1"
                            rootElement={document.getElementById("root")}
                            className='callButton'
                            text="Book a Discovery Call"
                        />                        
                        </div>
                </div>
            </div>
        </>
    );
}

export default AboutVidya;