import Cyber from '../assets/cyber.svg';
import AI from '../assets/ai.svg';
import Unique from '../assets/unique.svg';
import Data from '../assets/data.svg';
import Robotics from '../assets/robotics.svg';
import Food from '../assets/food.svg';
import Image2 from '../assets/9.png'
import Area from '../components/Area';


function AreasOfInterest() {
    return (
        <>
        <div className="container-fluid text-center">
            <div className="head-text tabPaddingTop pb-3">Areas <span className="bluetext">of Interest</span> </div>
        </div>
        <div className="row mb-5">
            <div className="col-lg-1"></div>
            <div className="col-lg">
                <Area image={Cyber} 
                    title={"Cyber Security"}
                    body="Cyber security is ever-evolving, with new risks emerging from both inside and outside the organization. 
                    When it comes to security, many companies and vendors make the mistake of treating it like a one-time thing. 
                    Vidya Advisor is committed to finding cybersecurity firms that develop cutting-edge security software."
                    />
                <Area image={Robotics} 
                    title={"Robotics and Automation"}
                    body="A technological revolution is taking place right in front of our eyes, shaping the world we live in. We invest 
                        in robotics and automation companies, both big and small, that are developing game-changing products and services 
                        for the future." 
                    />
            </div>
            <div className="col-lg">
                <Area image={AI} 
                    title="Artificial Intelligence"
                    body="Artificial Intelligence (AI) and Machine Learning (ML) are the primary focus areas for Vidya Advisor. We examine 
                    several firms in this field to identify whether AI/ML companies are developing products that have the potential 
                    to revolutionize the world. We look for businesses that have or will have a significant impact on the future of AI, 
                    such as those who develop the tools and infrastructure that support ubiquitous brands and essential needs.
                    Our current AI/ML investments include Graphcore, Scale.ai, and H2O.ai." 
                />
                <Area image={Data} 
                    title="Data Management"
                    body="Databases remain the core of any system. That's why every company needs this critical piece of infreastructure. 
                    Our research shows that database software will do well in any market. The days of limited functionality of the 
                    relational datavase have long passed, thanks to the release of new database that fill a critical gap in the market. 
                    With the popularity of AI/ML apps of the rise, there is a greater need to develop new systems quickly. 
                    The data management compaies in our portfolio include InfluxData, Redis Lab and Neo4J."
                />
            </div>
            <div className="col-lg">
                <Area image={Unique} 
                    title="Unique Technology"
                    body="Whether it's a disruptive innovation or a better way of doing things, the Vidya Advisor team always looks for the 
                    technology that will define the future. We also single out pioneering companies that are shaping the market and setting 
                    standards for various products.
                    Our current investments in unique technology include Lomenage, Unity, and Ginkgo Bioworks."
                />
                <Area image={Food} 
                    title="Food Technology"
                    body="Much like so many other essential global industries, the food sector is being revolutionized by new technology. 
                    That's why we're looking for and backing the most innovative and potentially game-changing new ventures in food technology."
                />
            </div>
            <div className="col-lg-1"></div>
        </div>

        <div className="row m-5 pt-5 ">
            <div className="col-lg ms-5 pt-5 text-center">
                <img src={Image2} alt="" className="Image2"></img>

            </div>
            <div className="col-lg me-5">
                <div className="head-text">
                    Giving More People Access To  <span className="bluetext"> Late-Stage Private Property Investments </span>
                    <br/>
                    
                </div>
                <div className="body-text">
                    By investing in alternative institutional capital on behalf of accredited retail investors, 
                    Vidya Advisor diversifies your holdings, minimizes your portfolio's sensitivity to market flucations, 
                    and improves your return on investment (ROI). We analyze organizations thoroughly within their industries, 
                    especially those that are in tranzition, in order to identify untapped opportunities. Our investment strategies 
                    are regularly assessed through the lens of new macro approaches and perspectives. <br/>
                    <br/>
                    For the benefit of our clients and partners, Vidya Advisor takes calculated risks in pursuit of 
                    potentially lucrative opportunities. We do extensive research to guarantee that our investments are 
                    in line with this goal.
                </div>
            </div>
        </div>
        </>
    );
}

export default AreasOfInterest;