export default function Area(props) {
    return (
    <div className="InterestText">
        <img src={props.image} alt="CyberSecurity" className="areasImg" />
        <span className="InterestTextHead">{props.title}</span>
        <div className="InterestTextBody">
            {props.body}
        </div>
    </div>
    );
}
