import Graphcore from "../assets/Graphcore-Logo.png";
import Impossible from "../assets/ImpossibleFoods-Logo.png";
import InfluxDB from "../assets/InfluxDB-Logo.png";
import Lemonade from "../assets/Lemonade-Logo.png";
import Neo4j from "../assets/Neo4j-Logo.png";
import Nuro from "../assets/Nuro-Logo.png";
import Orca from "../assets/Orca-Logo.png";
import Tesla from "../assets/Tesla-Logo.png";
import Twilio from "../assets/Twilio-Logo.png";
import Unity from "../assets/Unity-Logo.png";
import { PopupButton } from 'react-calendly';
import Image3 from '../assets/10.png';
import Image4 from '../assets/11.png';
import Tab from "../components/LSCTab";

function Strategy() {
    return (
        <>
        <div className="lightbg" >
            <div className="row m-5 pt-5">
                <div className="col-lg me-5 mt-2 pt-5">
                    <div className="head-text">
                        Our Strategy
                        <br />
                    </div>
                    <div className="h4 mt-5">
                        Improved Accessibility
                    </div>
                    <div className="body-text-narrow">
                        Back in the day, only large financial institutions were able to access certain investment opportunities.
                        With Vidya Advisory, qualified investors can have access to these markets and expand their holdings. <br />
                        <br />
                    </div>
                    <div className="h4">
                        Focus
                    </div>
                    <div className="body-text-narrow">
                        When making investments, Vidya Advisor looks for companies with a valuation of at least $500 million and
                        annual growth rates of at least 20%. To maximize alpha returns, we're partnered with some of the most powerful
                        institutional funds in the world to zero in on late-stage investments that can double or triple the initial investment. <br />
                        <br />
                    </div>
                </div>
                <div className="col-lg text-center ms-lg-5 me-lg-5 mt-5 pt-3">
                    <img src={Image3} alt="" className="Image2"></img>

                </div>
            </div>
        </div>
        <div className="container-fluid bg-white ">
            <div className="row m-5">
                <div className="col-lg-3">
                    <div className="head-text"><span className="bluetext">Our </span>Investment Process</div>
                </div>
                <div className="col-lg-9">
                    <div className="body-text">
                    The goal behind launching Vidya Advisor was to give investors access to late-stage private 
                    enterprises. We aim to identify comopanies with a $500 million to $5 billion value, a broad 
                    economic moat, massive competitive advantages, extremely innovative new technology, and a track 
                    record of profitable revenue growth. We focus on established companies because they can provide 
                    the returns of other investment types with significantly less and a much quicker period to liquidity.
                    </div>
                    
                </div>
            </div>

        </div>

        <div className="lightbluebg">
            <div className="container-fluid p-5 text-center">
                <div className="head-text">
                    <span className="bluetext">What Makes Our Process <br/> Different</span>
                </div>
                <br/>
                <span className="body-text">
                    We have two different approaches for startup investments and late-stage companies
                </span>
                <br/>
                <br/>
                <span className="h1">
                    For Late Stage Companies
                </span>

            </div>
            <div className="container-fluid pb-5 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-2 ms-1 me-1">
                    <Tab title="Qualitative Assessments"
                        body="To back up your investment thesis, our financial modeling services provide in-depth 
                        assessments across many scenarios, utilizing unique approaches and insights from 
                        industry professionals." />
                </div>
                <div className="col-lg-2 ms-1 me-1">
                    <Tab title="Strong Economic Footing"
                        body="We seek out a set of competitive advantages that make it tough to erode the company's market dominance, 
                        such as high barriers to entry and switching costs for consumers and enterprises." />
                </div>
                <div className="col-lg-2 ms-1 me-1">
                    <Tab title="Creative Foresight"
                        body="We look for innovative businesses that are driven by a desire to improve people's lives 
                        all across the world and can push technological boundaries. These companies aren't afraid to break 
                        the mold by aiming for results that their more rigid rivals might dissmiss as irrelevant." />
                </div>
                <div className="col-lg-2 ms-1 me-1">
                    <Tab title="Quantitative Screens"
                        body="Every week, we run a series of our own special screens to look for promising new investments based 
                        on industry developments, valuations, and macroeconomic trends. Companies experiencing exponential growth, 
                        using ground-breaking new technology or methods, and otherwise revolutionizing their industries are 
                        the focus of these screens." />

                </div>
                <div className="col-lg-2 ms-1 me-1">
                    <Tab title="Consistently High Cash Flow"
                        body="Our highly experienced and well-equipped management teams allow us to consistently provide excellent 
                        returns on investment." />

                </div>
                <div className="col-lg-1"></div>
            </div>
            <div className="text-center pb-5 ">
                <PopupButton
                    url="https://calendly.com/ftconsultants/30min?hide_event_type_details=1&hide_gdpr_banner=1"
                    rootElement={document.getElementById("root")}
                    className='callButton'
                    text="Book a Discovery Call"
                />            
                </div>
        </div>
        <div className="container-fluid p-5 row">
            <div className="col-lg text-center">
                <img src={Image4} alt="" className="Image2"></img>
            </div>
            <div className="col-lg m-5 p-5">
                <div className="head-text">
                    <span className="bluetext">For Our </span> Startups
                </div>
                <div className="body-text">
                    We take a more holistic approact when investing in early startups. We monitor them and see how 
                    they grow so they can possibly become late-stage companies. 
                </div>
            </div>
        </div>
        <div className="justify-content-center text-center" id="portfolio">
            <div className="lightbg tabPaddingTop">
                <div className="head-text-m">
                    <span className="bluetext">Latest Portfolio</span>
                </div>
                <div className="row m-1">
                    <div className="col-lg"></div>
                    <div className="col-md d-flex justify-content-center align-items-center">
                        <img className="portfolioLogoImage" src={InfluxDB} alt="InfluxDB" />
                    </div>
                    <div className="col-md d-flex justify-content-center align-items-center">
                        <img className="portfolioLogoImage" src={Impossible} alt="ImpossibleFoods" />
                    </div>
                    <div className="col-md d-flex justify-content-center align-items-center">
                        <img className="portfolioLogoImage" src={Graphcore} alt="Graphcore" />
                    </div>
                    <div className="col-lg"></div>
                </div>
                <div className="row m-1">
                    <div className="col-lg"></div>
                    <div className="col-md d-flex justify-content-center align-items-center">
                        <img className="portfolioLogoImage" src={Nuro} alt="Nuro" />
                    </div>
                    <div className="col-md d-flex justify-content-center align-items-center">
                        <img className="portfolioLogoImage" src={Neo4j} alt="Neo4j" />
                    </div>
                    <div className="col-md d-flex justify-content-center align-items-center">
                        <img className="portfolioLogoImage" src={Orca} alt="Orca" />
                    </div>
                    <div className="col-lg"></div>
                </div>
                <div className="head-text-m">
                    <span className="bluetext">Exit Portfolio</span>
                </div>
                <div className="row m-1">
                    <div className="col-lg"></div>
                    <div className="col-md d-flex justify-content-center align-items-center">
                        <img className="portfolioLogoImage" src={Unity} alt="Unity" />
                    </div>
                    <div className="col-md d-flex justify-content-center align-items-center">
                        <img className="portfolioLogoImage" src={Lemonade} alt="Lemonade" />
                    </div>
                    <div className="col-lg"></div>
                </div>
                <div className="row mt-1 pb-lg-5">
                    <div className="col-lg"></div>
                    <div className="col-md d-flex justify-content-center align-items-center">
                        <img className="portfolioLogoImage" src={Twilio} alt="Twilio" />
                    </div>
                    <div className="col-md d-flex justify-content-center align-items-center">
                        <img className="portfolioLogoImage" src={Tesla} alt="Tesla" />
                    </div>
                    <div className="col-lg"></div>
                </div>

                </div>
            </div>
        </>
    );
}

export default Strategy;