import { PopupButton } from 'react-calendly';
import Sanjay from '../assets/Sanjay_profile_photo.jpg';
import Ajay from '../assets/Ajay_profile_photo.jpg';
import React, { useState } from 'react';
import TeamModal from '../components/teamModal';

function Team() {
    const [modalShowAjay, setModalShowAjay] = useState(false);
    const [modalShowSanjay, setModalShowSanjay] = useState(false);
    return (
        <div className="container-fluid bg-light row p-5">
            <div className="col-lg-1"></div>
            <div className="col-lg text-center">
                
                <button variant="primary" className='modalButton' onClick={() => setModalShowAjay(true)}>
                    <img className="profileImage" alt="Ajay Arora" src={Ajay} />
                    <div className='profileName'>Ajay Arora</div>
                </button>
                <TeamModal 
                    show={modalShowAjay} 
                    onHide={() => setModalShowAjay(false)} 
                    title='Ajay Arora' 
                    body='Ajay Arora is a partner of Vidya Series Fund. A family office that manages its personal wealth as well of his family 
                    and friends by investing in private companies in which he has deep knowlege. Ajay Arora is also a Venture partner with Sakal 
                    Ventures. He serves as  Sakal Ventures head of research. His  primary focus is to develop, research, and direct the execution 
                    of investment strategies. Prior to Sakal Ventures, Ajay was a Technology Advisor to Sakal Capital Management.
                    Prior to become a VC. Ajay has varied entrepreneur experience.  He founded Silver Motion and eRSVP, and was a strategic 
                    advisor to a diverse stack of technology, fin-tech, marketing, e-commerce, and gaming companies.
                    Ajay has been a private investor for over thrity years. He has identified and consulted investors in many strong growth 
                    companies in their early stages such as: Unity, MongoDB, Sea Limited, Twillio, Tesla, and Lemonade.
                    His current portfolio consist of companies like InfluxDB, Apeel Science, Redis Labs, Nuro, Neo4J, Impossible Food, 
                    Graphcore, Gingoko Bioworks, H20.ai, Orca security and continue to add more companies in the portfolio or increasing its stake.  
                    Between Sakal and Vidya he is managing fund of 50 Million+. Ajay’s entrepreneur and business experience along with deep understanding 
                    of technology has given him the edge of identifying key elements to identify many great companies of tomorrow today.  Ajay earned a 
                    Bachelor of Science from NYU Stern School of Business in Information Systems & Accounting.'
                />
            </div>
            
            <div className="col-lg text-center">
                <button variant="primary" className='modalButton' onClick={() => setModalShowSanjay(true)}>
                    <img className="profileImage" alt="Sanjay Arora" src={Sanjay} />
                    <div className='profileName'>Sanjay Arora</div>
                </button>
                <TeamModal 
                    show={modalShowSanjay} 
                    onHide={() => setModalShowSanjay(false)} 
                    title='Sanjay Arora' 
                    body="Sanjay K Arora, Accountant and Registered Tax Preparer, is the Partner and at Vidya Advisory LLC. 
                    Sanjay is also a member of the firm’s Executive, Investment, Risk, and Compliance committees. 
                    He is responsible for the operation of the investor relations, legal, compliance, technology, 
                    administration, facilities, and human resources departments. Sanjay’s skills include Business Consulting, 
                    Insurance, Accounting and Taxation. Sanjay continues to successfully run and operate various business 
                    that provide these services. With an experience of 30 plus years, Sanjay has built a unique knowledge 
                    and worked with a broad array and number of business and individuals in service, ecommerce, technology, 
                    service, and retail industries ranging in size from start-up to Fortune 1000 clients. Sanjay also has 
                    volunteered him time and expertise to various nonprofit organizations throughout the years."
                />
            </div>
            <div className="col-lg mt-5 pt-5">
                <span className="head-text">Team</span>
                <br/>
                <br/>
                <PopupButton
                    url="https://calendly.com/ftconsultants/30min?hide_event_type_details=1&hide_gdpr_banner=1"
                    rootElement={document.getElementById("root")}
                    className='callButton'
                    text="Book a Discovery Call"
                />            
                </div>

        </div>
    );
}

export default Team;