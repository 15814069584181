import React, { useState } from "react";
import Image5 from '../assets/12.png';

const FORM_ENDPOINT = "https://public.herotofu.com/v1/19101f10-1c37-11ee-85cf-0fe283fcf216"; 


function HomeFooter() {

    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (e) => {
      e.preventDefault();
  
      const inputs = e.target.elements;
      const data = {};
  
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].name) {
          data[inputs[i].name] = inputs[i].value;
        }
      }

      if (data.name.length === 0) {
        alert('Invalid Form, Name can not be empty');
        return;
      }

      if (data.email.length === 0) {
        alert('Invalid Form, Email can not be empty');
        return;
      }

      if (data.message.length === 0) {
        alert('Invalid Form, Message can not be empty');
        return;
      }
  
      fetch(FORM_ENDPOINT, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Form response could not be submitted.');
          }
  
          setSubmitted(true);
        })
        .catch((err) => {
          e.target.submit();
        });
    };
  
    if (submitted) {
      return (
        <>
          <div className="darkbluebg">
            <div className="row ">
                <div className="col text-center align-items-center p-5 m-5">
                    <div className="head-text">
                        <span className="whitetext">
                          Thank You! <br/> We'll get back to you soon!
                        </span>
                    </div>
                        
                </div>
                <div className="col text-center p-5">
                    <img src={Image5} alt="" className="Image3"></img>
                </div>
            </div>
            
        </div>
          
          
        </>
      );
    }
  
    return (
        <div className="darkbluebg">
            <div className="row ">
                <div className="col text-center align-items-center p-5">
                    <div className="head-text-s">
                        Get in touch <br/> with us
                    </div>
                    <div className="form-align">
                        <span>
                            <form 
                            action={FORM_ENDPOINT}
                            onSubmit={handleSubmit}
                            method="POST"
                            >
                            <div class="form-floating">
                            <input type="text" className="form-control" id="floatingInput" placeholder="Name*" name="name" required />
                            <label for="floatingInput"></label>
                            </div>
                            <div class="form-floating">
                            <input type="email" className="form-control" id="floatingInput" placeholder="Email*" name="email" required />
                            <label for="floatingInput"></label>
                            </div>
                            <div>
                            <textarea className="form-control" id="floatingInput" placeholder="Message*" name="message" rows={4} required />
                            </div>
                            <div class="form-floating">
                            <button className="submitButton"type="submit"> Send Inquery </button>
                            </div>
                            </form>
                        </span>
                    </div>
                        
                </div>
                <div className="col text-center p-5">
                    <img src={Image5} alt="" className="Image3"></img>
                </div>
            </div>
            
        </div>
    );
  };

export default HomeFooter;