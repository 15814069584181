export default function Tab(props) {
    return (
    <>
        <div className="tabular-header">
            {props.title}
        </div><div className="tabular-body">
            {props.body}
        </div>
    </>
    );
}